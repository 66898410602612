<template>
    <div class="loading_wrap" :style="{
        'width': size + 'px',
        'height': size + 'px',
        'border-width': borderSize + 'px',
        'border-color': borderColor
    }">
    </div>
</template>

<script>
export default {
    name: 'loading',
    props: {
        size: {
            type: Number,
            default: () => 0,
        },
        borderSize: {
            type: Number,
            default: () => 0,
        },
        borderColor: {
            type: String,
            default: () => '#fff',
        }
    },
    created() {
    },
    watch: {

    }
}
</script>

<style scoped lang="less">
.loading_wrap {
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 6px solid #fff;
    border-left-color: #fd7c61!important;
    animation: loading 1s linear infinite;
    margin: 0 auto;

}

@keyframes loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
</style>